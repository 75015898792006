/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"

// import "./layout.css"


/*
import "../assets/bootstrap/bootstrap.min.css"
import "../assets/bootstrap/jquery-3.4.1.slim.min.js"
import "../assets/bootstrap/popper.min.js"
import "../assets/bootstrap/bootstrap.min.js"
*/


import "./assets/css/styles.css"
import "./assets/common.8f91687f5bb8cee0ff5c.css"
import "./assets/default-page.55ace10a4e161b80154e.css"

const Layout = ({ children }) => {

    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <>
            <Header siteTitle={data.site.siteMetadata.title} />
            <div>
                <main>{children}</main>
            </div>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
