import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import adams_training_data from "../images/adams_training_data.png"


const Header = ({ siteTitle }) => (
    <div className="page__header">

        {/*<!--top-menu-->*/}
        <header className="menu-main header header_float" id="js-menu-main">
            <div className="wt-container menu-main__container">
                <div className="wt-row wt-row_size_0 wt-row_wide wt-row_wrap wt-row_align-items_center menu-main__items-wrapper">

                    <a href="/" className="wt-col-auto-fill wt-text-1 wt-text-1_theme_dark menu-main__item plain">
                        <svg className="bi bi-shield-fill adams-green mb10" width="32" height="32" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style={{lineHeight: '0.1em'}}>
                            <path fill-rule="evenodd" d="M7.187 3.025C8.23 2.749 9.337 2.5 10 2.5c.662 0 1.77.249 2.813.525 1.066.282 2.14.614 2.772.815.528.168.926.623 1.003 1.184.573 4.197-.756 7.307-2.367 9.365a11.192 11.192 0 01-2.418 2.3 6.942 6.942 0 01-1.007.586c-.27.124-.558.225-.796.225s-.527-.101-.796-.225a6.908 6.908 0 01-1.007-.586 11.192 11.192 0 01-2.418-2.3c-1.611-2.058-2.94-5.168-2.367-9.365A1.454 1.454 0 014.415 3.84a61.113 61.113 0 012.772-.815z" clip-rule="evenodd"></path>
                        </svg>
                        <span className="f25 bold">
                            {siteTitle}
                        </span>
                    </a>

                    <a className="wt-col-inline wt-text-1 wt-text-1_theme_dark menu-main__item plain" href="#features">Features</a>

                    <div className="nav-linkx dropdown-toggle wt-col-inline wt-text-1 wt-text-1_theme_dark menu-main__item plain" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Modules
                    </div>
                    <div className="dropdown-menu image_shadow_mildx dark-bgx" aria-labelledby="navbarDropdown">


                        {/*
                        <div className="rowx">
                            <div className="col-5x f17">
                            */}
                                <div className="dropdown-item adams-green bold plain-link" href="#features">ADAMS Modules</div>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="#training">Training and Qualification</a>
                                <a className="dropdown-item" href="#work">Work Tracking</a>
                                <a className="dropdown-item" href="#sofa">SOFA</a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item bold" href="#registers">Registers</a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="#register-cert">Certified Operators and Aviation Service <br/>Providers Records</a>
                                <a className="dropdown-item" href="#register-pel">Licensed Personnel Records</a>
                                <a className="dropdown-item" href="#register-aircraft">Aircraft Records and Equipment</a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="#arclive">ARCLIVE</a>
                                <a className="dropdown-item" href="#amcas">AMCAS</a>
                                <a className="dropdown-item null" href="#sir">Safety Issues Resolution</a>
                                <a className="dropdown-item null" href="#eir">Enforcement and Investigation</a>
                            {/*
                            </div>
                            <div className="col-6 null">
                                <br />
                                <img className="overview-screen__image image_shadowx" src={adams_training_data} width="400px" />
                                <br />
                                <br />
                                <h3 className="h3-section">Training Module</h3>
                                <div className="features-block">
                                    <h4 className="h4-section">First Anniversary</h4>
                                    <p>2000+ Aviation Activities, 8 Specialties, 9 Activities Groups, Safety Issues and Enforcement</p>
                                    <br/>
                                    <a className="btn btn-success" href="#">
                                        Request a Demo
                                    </a>
                                </div>
                            </div>
                        </div>
                        */}
                    </div>

                    <a className="wt-col-inline wt-text-1 wt-text-1_theme_dark menu-main__item plain null" href="#training">Training</a>
                    <a className="wt-col-inline wt-text-1 wt-text-1_theme_dark menu-main__item plain null" href="#work">Work Tracking</a>
                    <a className="wt-col-inline wt-text-1 wt-text-1_theme_dark menu-main__item plain null" href="#sofa">Ramp Inspections</a>
                    <a className="wt-col-inline wt-text-1 wt-text-1_theme_dark menu-main__item plain" href="#registers">Registers</a>
                    <a className="wt-col-inline wt-text-1 wt-text-1_theme_dark menu-main__item plain null" href="#support">Support</a>

                    <a className="btn btn-success ml20 mr20" href="#">
                        Request a Demo
                    </a>

                    <a className="wt-col-inline menu-main__action-icon">
                        <svg viewBox="0 0 24 24" className="wt-icon wt-icon_size_m wt-text-2_theme_dark wt-text_hardness_average">
                            <path d="M2.293 10a6.99 6.99 0 0 0 11.187 5.6l6.106 6.107L21 20.293l-6.106-6.106A6.997 6.997 0 1 0 2.293 10zm2 0a5 5 0 1 1 5 5 5 5 0 0 1-5-5z"></path>
                        </svg>
                    </a>


                </div>
            </div>
        </header>
        {/*<!--top-menu-->*/}

        {/*<!--sub-menu-->*/}
        <div className="menu-second null" id="js-menu-second" style={{position:'absolute', width:'100vw', top:'70px', left:'0px', }}>
            <div className="wt-container">
                <div id="js-menu-second-mobile-wrapper" className="wt-display-none">
                    <div id="js-menu-second-mobile">
                        <div className="menu-second-mobile wt-row wt-row_size_m wt-row_align-items_center wt-row_justify_between ">
                            <div className="wt-col-inline menu-second-skeleton-text-2" style={{maxWidth: '120px'}}></div>
                            <div className="wt-col-inline menu-second-skeleton-button" style={{maxWidth: '80px'}}></div>
                        </div>
                    </div>
                </div>

                <div id="js-menu-second-desktop" className="menu-second-desktop wt-row wt-row_size_0 wt-row_align-items_center">

                    <div className="wt-col-auto-fill">
                        <a className="menu-second-title-box" href="https://www.jetbrains.com/phpstorm/">
                            <span className="menu-second-title-box__logo  jetbrains-logo">
                            </span>
                            <span className="menu-second-title-box__title wt-h3 null">{siteTitle}</span>
                        </a>
                    </div>

                    <a href="https://www.jetbrains.com/phpstorm/whatsnew/" className="wt-col-inline wt-text-2  wt-text-2_hardness_average menu-second__link">
                        What's New
                    </a>
                    <a href="https://www.jetbrains.com/phpstorm/features/" className="wt-col-inline wt-text-2  wt-text-2_hardness_average menu-second__link">
                        Features
                    </a>
                    <a href="https://www.jetbrains.com/phpstorm/documentation/" className="wt-col-inline wt-text-2  wt-text-2_hardness_average menu-second__link">
                        Learn
                    </a>
                    <a href="https://www.jetbrains.com/phpstorm/social/" className="wt-col-inline wt-text-2  wt-text-2_hardness_average menu-second__link">
                        Blog &amp; Social
                    </a>
                    <a href="https://www.jetbrains.com/phpstorm/buy/" className="wt-col-inline wt-text-2  wt-text-2_hardness_average menu-second__link">
                        Buy
                    </a>
                    <a className="button js-popup fs-lightbox-element" href="https://youtu.be/pP9jw3fgrCU">
                        Demo
                    </a>
                    {/*<!--a href="https://www.jetbrains.com/phpstorm/download/" className="wt-col-inline menu-second__download-button wt-button wt-button_size_swt-button_mode_primary">
                        Download
                    </a-->*/}
                </div>
            </div>
        </div>
        {/*<!--sub-menu-->*/}

        <span className="fixer-placeholder header-fixer-placeholder"></span>

    </div>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
